const my = {
  email: {
    required: 'Sila masukkan emel anda',
    valid: 'Alamat emel tidak sah',
  },
  password: {
    required: 'Sila masukkan kata laluan anda',
    min: 'Perlu lebih daripada 6 aksara',
  },
  digit: 'Sila masukkan nombor sahaja',

  fullNameRequired: 'Nama penuh diperlukan',
  CANT_PUBLIC_BANNER:
    'Neither banner image Eng nor banner image MY is allowed to be empty',

  banner: {
    emptyMyBanner: '"Banner in Bahasa Malaysia" is required',
    emptyEnBanner: '"Banner in English" is required',
    emptyUrl: '"url" is not allowed to be null when it\'s selected',
    emptyScreenName:
      '"screenName" is not allowed to be null when it\'s selected',
    emptyNavigation: '"navigation" is required',
    emptyTitle: '"Title" is required',
  },
};

export default my;
