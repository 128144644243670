import get from 'lodash/get';

import packageJson from '../../package.json';

export const VERSION = get(packageJson, 'version', 'undefined');

export const accountRole = {
  superAdmin: 'SUPER_ADMIN',
  admin: 'ADMIN',
};
