const my = {
  routeName: {
    home: 'Halaman utama',
    stores: 'Lokasi',
    QR: 'Jana kod QR',
    transactionHistory: 'Sejarah transaksi',
    setting: 'Tetapan',
    logs: 'Rekod tukaran syiling',
    banners: 'Banners',
    accountManager: 'Account manager',
    juaraApplications: 'Juara Applications',
    tenangApplications: 'Tenang Applications',
  },
  logOut: {
    label: 'Log keluar',
    confirmTitle: 'Sahkan log keluar',
    confirmMessage: 'Adakah anda pasti mahu log keluar?',
  },

  digit: 'Digit',
  email: 'Emel',
  language: 'Bahasa',
  password: 'Kata laluan',
  submit: 'Hantar',
  save: 'Simpan',

  coinDeposit: 'TUKARAN SYILING',
  status: 'STATUS',
  success: 'BERJAYA',
  transactionId: 'ID TRANSAKSI',
  clientFullName: 'NAMA PENUH PELANGGAN',
  clientPhoneNumber: 'NOMBOR TELEFON BIMBIT PELANGGAN',
  timeCreated: 'MASA DICIPTA',
  transactionFee: 'TCAJ TRANSAKSI',
  back: 'KEMBALI',

  id: 'ID',
  resendEmail: 'Hantar semula emel',
  emailVerifySent:
    'Kod 6-digit telah dihantar kepada anda melalui emel dan SMS.',
  login: 'Log masuk',
  verifyEmail: 'Sahkan alamat emel',
  changeOnlineHours: 'Kemaskini waktu operasi',
  active: 'Aktif',

  forgotPassword: 'Lupa kata laluan?',
  account: 'Akaun',
  changePassword: 'Ubah kata laluan',
  currentPassword: 'Kata laluan kini',
  newPassword: 'Kata laluan baharu',
  confirmNewPassword: 'Sahkan kata laluan baharu',
  passwordNotMatch: 'Kata laluan baharu tidak sepadan',
  changePasswordSuccess: 'Kata laluan berjaya diubah',
  backToLogin: 'Kembali ke log masuk',
  forgotPass: {
    emailSent: 'Semak emel anda',
    message1: 'Emel telah dikirim kepada',
    message2: 'Ikuti arahan dalam emel untuk set semula kata laluan anda',
    messageEnterMail:
      'Masukkan alamat emel anda. Kami akan mengirim pautan untuk set semula kata laluan anda',
  },
  resetPassword: 'Set semula kata laluan',
  continue: 'Teruskan',

  welcome: 'Welcome',
  messCreateYourPassword: 'Create your password so we can get it to work',
  createPasswordSuccess: 'Password created successfully',
  loginToStart: "Let's login to start your work",
  createPassword: 'Create password',
  createAdmin: 'Cipta admin',
  fullName: 'Nama penuh',
  role: 'Peranan',
  admin: 'Admin',
  superAdmin: 'Super admin',
  createSuccess: 'Berjaya dicipta',
  createdAt: 'Created at',
  adminNotFound: 'Admin tidak dijumpai',
  backToAdminManage: 'Kembali kepada pengurusan admin',
  confirmInactiveAdmin: {
    title: 'Nyahaktifkan akaun admin',
    message:
      'Akaun ini akan dilog keluar daripada semua peranti dan tidak dapat log masuk semula. Teruskan?',
  },
  confirmActiveAdmin: {
    title: 'Active an admin account',
    message: 'Akaun ini boleh log masuk ke halaman ini. Teruskan?',
  },
  editAdmin: 'Ubah suai admin',
  updateSuccess: 'Kemaskini berjaya',
  banner: {
    screenName: 'Screen name',
    newBanner: 'New banner',
    labelCreate: 'Create a new banner',
    enBanner: 'Banner in English',
    myBanner: 'Banner in Bahasa Malaysia',
    private: 'Private',
    public: 'Public',
    confirmPublicMessage:
      "Are you sure? This action is going to affect clients' UI. Please confirm to continue.",
    linkedToScreen: 'Linked to screen',
    bannerList: 'Banner List',
    navigation: 'navigation',
    url: 'URL',
    selectScreenName: 'Select a screen name',
    inputURL: 'Type an URL',
    screen: 'Screen',
    linkedToURL: 'Linked to URL',
    none: 'None',
  },
  confirm: 'Confirm',
  cancel: 'Cancel',
  delete: 'Delete',
  updateFailed: 'Update failed',
  recommendedSize: 'Recommended size:',
  warnWhenUnsavedChanges:
    "Some of your changes weren't saved. Are you sure want to ignore them?",
  warning: 'Warning',
  confirmToDelete: 'Deleted data can not be restored. Do you want to delete?',
  clickOrDragToUpload: 'Click or drag file to this area to upload',
  clientIc: 'Client Ic',
  clientPhone: 'Client Phone',
  clientEmail: 'Client Email',
  loanId: 'Loan Id',
  offeredAmount: 'Offered Amount',
  loanAmountApplied: 'Loan Amount Applied',
  processedDate: 'Processed Date',
  search: 'Search',
  resetFilter: 'Reset Filter',
  exportCurrentPage: 'Export current page',
  excelFile: 'Excel file (.xlsx)',
  csvFile: 'CSV file (.csv)',

  applicationId: 'Application ID',
  userID: 'User ID',
  applicantName: 'Applicant name',
  idNumber: 'ID number',
  phone: 'Phone',
  gender: 'Gender',
  refId: 'Ref ID',
  applicationStatus: 'Status',
  dateCreated: 'Date created',
  underwritingIneligible: 'ineligible due to underwriting',
  declined: 'Declined',
  approved: 'Approved',
  processing: 'Processing',
  refundStatus: 'Refund Status',
  Refund: 'Refund',
  paymentNumber: 'Payment Number',
  refundFilter: 'Refund Filter',
  needRefundingLabel: 'Need Refunding',
  needRefundingTooltip:
    'Need Refunding: The application is eligible for refunding.',
  waitingForAuthorizeFpxTooltip:
    'Waiting For Authorization (FPX): The transaction needs to be approved.',
  waitingForAuthorizeCimbTooltip:
    'Waiting For Authorization (CIMB): The transaction needs to be approved.',
  refundAuthorizedTooltip:
    'Refund Authorized: Transaction made, waiting for the payment transfer to reach the refunded user.',
  completedStatusTooltip:
    'Completed: The refund was completed and the refunded user received it.',
  markAsRefund: 'Mark as Refund',
  authorize: 'Authorize',
  confirmInitRefund:
    'Are you sure you want to initialize refunding for this application?',
  confirmAuthorizeFpx:
    'Are you sure you want to mark this application refunding as FPX Was Authorized?',
  confirmAuthorizeCimb:
    'Are you sure you want to mark this application refunding as CIMB Was Authorized?',
  confirmMarkAsRefund:
    'Are you sure you want to mark the refunding for this application as completed?',
  authorizeFpx: 'Authorize FPX',
  authorizeCimb: 'Authorize CIMB',
  OK: 'OK',
  Cancel: 'Cancel',
  Completed: 'Completed',
  waitingForAuthorizationFpx: 'Waiting For Authorization (FPX)',
  waitingForAuthorizationCimb: 'Waiting For Authorization (CIMB)',
  refundAuthorized: 'Refund Authorized',
};

export default my;
