import React, { Suspense } from 'react';
import 'antd/dist/antd.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout';

import { isLoggedInSelector } from 'reduxStore/auth/selectors';
import PageLoading from 'components/PageLoading';
import useRouteByRole from './useRouteByRole';

const Routes = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const routes = useRouteByRole();
  const defaultRoute = routes[0];

  const WrapperElement = isLoggedIn ? Layout : React.Fragment;
  return (
    <Suspense
      fallback={
        <WrapperElement routes={routes}>
          <PageLoading />
        </WrapperElement>
      }>
      <WrapperElement routes={routes}>
        <Switch>
          {routes.map((routeItem, index) => (
            <Route
              key={index}
              path={routeItem.path}
              component={routeItem.component}
              exact={routeItem.exact}
            />
          ))}

          {defaultRoute && <Redirect to={defaultRoute.path} />}
        </Switch>
      </WrapperElement>
    </Suspense>
  );
};

export default Routes;
