const routeLinks = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
  welcome: '/welcome/:token',
  generateQR: '/generate-qr-code',
  changePassword: '/account/change-password',
  setting: '/setting',
  transactionHistory: '/transaction-history',
  listAccount: '/account',
  createAccount: '/account/create',
  editAccount: '/account/:id',

  banners: '/banners',
  juaraApplicatons: '/juara/applications',
  tenangApplicatons: '/tenang/applications',
};

export default routeLinks;
