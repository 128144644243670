const en = {
  email: {
    required: 'Please input your email',
    valid: 'Invalid email address',
  },
  password: {
    required: 'Please input your password',
    min: 'Requires more than 6 characters',
  },
  digit: 'Enter the code we just sent to your email',

  fullNameRequired: 'Full name is required',
  CANT_PUBLIC_BANNER:
    'Neither banner image Eng nor banner image MY is allowed to be empty',

  banner: {
    emptyMyBanner: '"Banner in Bahasa Malaysia" is required',
    emptyEnBanner: '"Banner in English" is required',
    emptyUrl: '"url" is not allowed to be null when it\'s selected',
    emptyScreenName:
      '"screenName" is not allowed to be null when it\'s selected',
    emptyNavigation: '"navigation" is required',
    emptyTitle: '"Title" is required',
  },
};

export default en;
