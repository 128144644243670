import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  LogoutOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { VERSION } from 'variables';
import { logoMypayLight, logoDapatLight } from 'images';
import { mapLanguageToLocale } from 'setting/moment';
import routeLinks from 'Routes/routeLinks';
import PopupLogout from './PopupLogout';
import { LANGUAGES } from 'i18n/configs';
import LayoutStyled from './styled';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function PageLayout(props) {
  const { routes } = props;
  const { t, i18n } = useTranslation();
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const location = useLocation();

  const onClickChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    moment.locale(mapLanguageToLocale[lang]);
  };

  const showModalLogout = () => {
    setOpenModalLogout(true);
  };
  const onClosePopup = () => {
    setOpenModalLogout(false);
  };

  const routesToShow = useMemo(() => {
    return routes.filter((route) => {
      const { showOnSideBar = true } = route;
      return showOnSideBar;
    });
  }, [routes]);

  const selectedMenuItem = useMemo(() => {
    const selectedIndex = routesToShow.findIndex((route) =>
      route.path.includes(location.pathname),
    );
    return [selectedIndex + ''];
  }, [location.pathname, routesToShow]);

  return (
    <LayoutStyled>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider className='ant-sider' breakpoint='md' collapsedWidth='0'>
          <Menu theme='dark' mode='inline' selectedKeys={selectedMenuItem}>
            {routesToShow.map((routeItem, index) => (
              <Menu.Item key={index} icon={routeItem.icon}>
                <Link to={routeItem.path}>{t(routeItem.name)}</Link>
              </Menu.Item>
            ))}
          </Menu>

          <Menu
            theme='dark'
            mode='vertical'
            className='menu-fixed-bottom'
            selectedKeys={[i18n.language]}>
            <a href='https://mypay.com.my/' target='_blank' rel='noreferrer'>
              <img src={logoMypayLight} alt='MyPay_Logo' className='logo' />
            </a>
            <a href='https://www.dapat.com/' target='_blank' rel='noreferrer'>
              <img src={logoDapatLight} alt='Dapat_Logo' className='logo' />
            </a>
            <SubMenu title={t('account')} icon={<UserOutlined />}>
              <Menu.Item>
                <Link to={routeLinks.changePassword}>
                  {t('changePassword')}
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu title={t('language')} icon={<TranslationOutlined />}>
              {LANGUAGES.map((langItem) => (
                <Menu.Item
                  key={langItem.value}
                  onClick={() => onClickChangeLanguage(langItem.value)}>
                  {langItem.label}
                </Menu.Item>
              ))}
            </SubMenu>
            <Menu.Item onClick={showModalLogout} icon={<LogoutOutlined />}>
              {t('logOut.label')}
            </Menu.Item>
          </Menu>
        </Sider>
        <PopupLogout visible={openModalLogout} onClose={onClosePopup} />

        <Layout className='site-layout'>
          {props.children}{' '}
          <div className='footer-text'>
            &copy; Dapat Vista Sdn Bhd. All rights reserved&nbsp;&nbsp;
            <span>&bull;&nbsp;&nbsp;Version {VERSION}</span>
          </div>
        </Layout>
      </Layout>
    </LayoutStyled>
  );
}
