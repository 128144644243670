import 'antd/dist/antd.css';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { initPageFinishSelector } from 'reduxStore/auth/selectors';
import authAction from 'reduxStore/auth/actions';
import PageLoading from 'components/PageLoading';
import Routes from '../Routes';

export default function App() {
  const initPageFinish = useSelector(initPageFinishSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authAction.loginByToken());
  }, [dispatch]);

  if (!initPageFinish) return <PageLoading fullScreen />;

  return <Routes />;
}
