const en = {
  routeName: {
    home: 'Home',
    stores: 'Locations',
    QR: 'Generate QR code',
    transactionHistory: 'Transactions History',
    setting: 'Settings',
    logs: 'Coin conversion logs',
    banners: 'Banners',
    accountManager: 'Account manager',
    juaraApplications: 'Juara Applications',
    tenangApplications: 'Tenang Applications',
  },
  logOut: {
    label: 'Log out',
    confirmTitle: 'Log out confirmation',
    confirmMessage: 'Are you sure you want to logout?',
  },

  digit: 'Digit',
  email: 'Email',
  language: 'Language',
  password: 'Password',
  submit: 'Submit',
  save: 'Save',

  coinDeposit: 'COIN CONVERSION',
  status: 'STATUS',
  success: 'SUCCESS',
  transactionId: 'TRANSACTION ID',
  clientFullName: 'CUSTOMER FULL NAME',
  clientPhoneNumber: 'CUSTOMER MOBILE PHONE NUMBER',
  timeCreated: 'TIME CREATED',
  transactionFee: 'TRANSACTION FEE',
  back: 'BACK',

  id: 'ID',
  resendEmail: 'Resend email',
  emailVerifySent:
    'A 6-digit code has been sent to you via email. Enter it below',
  login: 'Login',
  verifyEmail: 'Verify email address',
  changeOnlineHours: 'Edit operating hours',
  active: 'Active',

  account: 'Account',
  changePassword: 'Change password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  passwordNotMatch: 'New passwords do not match!',
  changePasswordSuccess: 'Password changed successfully',
  forgotPassword: 'Forgot password?',
  backToLogin: 'Back to log in',
  forgotPass: {
    emailSent: 'Check your email',
    message1: 'An email has been sent to',
    message2: 'Follow the directions in the email to reset your password',
    messageEnterMail:
      "Enter your email address. We'll send you a link to reset your password",
  },
  resetPassword: 'Reset password',
  continue: 'Continue',

  welcome: 'Welcome',
  messCreateYourPassword: 'Create your password so we can get it to work',
  createPasswordSuccess: 'Password created successfully',
  loginToStart: "Let's login to start your work",
  createPassword: 'Create password',
  createAdmin: 'Create admin',
  fullName: 'Full name',
  role: 'Role',
  admin: 'Admin',
  superAdmin: 'Super admin',
  createSuccess: 'Create successfully',
  createdAt: 'Created at',
  adminNotFound: 'Admin not found.',
  backToAdminManage: 'Back to Admin management',
  confirmInactiveAdmin: {
    title: 'Deactivate an admin account',
    message:
      'This account will be logged out from every device and will no longer be able to login again. Continue?',
  },
  confirmActiveAdmin: {
    title: 'Activate an admin account',
    message: 'This account can login to this page. Continue?',
  },
  editAdmin: 'Edit admin',
  updateSuccess: 'Update successfully',
  banner: {
    screenName: 'Screen name',
    newBanner: 'New banner',
    labelCreate: 'Create a new banner',
    enBanner: 'Banner in English',
    myBanner: 'Banner in Bahasa Malaysia',
    private: 'Private',
    public: 'Public',
    confirmPublicMessage:
      "Are you sure? This action is going to affect clients' UI. Please confirm to continue.",
    linkedToScreen: 'Linked to screen',
    bannerList: 'Banner List',
    navigation: 'navigation',
    url: 'URL',
    selectScreenName: 'Select a screen name',
    inputURL: 'Type an URL',
    screen: 'Screen',
    linkedToURL: 'Linked to URL',
    none: 'None',
  },
  confirm: 'Confirm',
  cancel: 'Cancel',
  delete: 'Delete',
  updateFailed: 'Update failed',
  recommendedSize: 'Recommended size:',
  warnWhenUnsavedChanges:
    "Some of your changes weren't saved. Are you sure want to ignore them?",
  warning: 'Warning',
  confirmToDelete: 'Deleted data can not be restored. Do you want to delete?',
  clickOrDragToUpload: 'Click or drag file to this area to upload',
  clientIc: 'Client Ic',
  clientPhone: 'Client Phone',
  clientEmail: 'Client Email',
  loanId: 'Loan Id',
  offeredAmount: 'Offered Amount',
  loanAmountApplied: 'Loan Amount Applied',
  processedDate: 'Processed Date',
  search: 'Search',
  resetFilter: 'Reset Filter',
  exportCurrentPage: 'Export current page',
  excelFile: 'Excel file (.xlsx)',
  csvFile: 'CSV file (.csv)',

  applicationId: 'Application ID',
  userID: 'User ID',
  applicantName: 'Applicant name',
  idNumber: 'ID number',
  phone: 'Phone',
  gender: 'Gender',
  refId: 'Ref ID',
  applicationStatus: 'Status',
  dateCreated: 'Date created',
  refundStatus: 'Refund Status',
  Refund: 'Refund',
  rejected: 'Rejected',
  underwritingIneligible: 'ineligible due to underwriting',
  declined: 'Declined',
  approved: 'Approved',
  processing: 'Processing',
  refundFilter: 'Refund Filter',
  needRefundingLabel: 'Need Refunding',
  needRefundingTooltip:
    'Need Refunding: The application is eligible for refunding.',
  waitingForAuthorizeFpxTooltip:
    'Waiting For Authorization (FPX): The transaction needs to be approved.',
  waitingForAuthorizeCimbTooltip:
    'Waiting For Authorization (CIMB): The transaction needs to be approved.',
  refundAuthorizedTooltip:
    'Refund Authorized: Transaction made, waiting for the payment transfer to reach the refunded user.',
  completedStatusTooltip:
    'Completed: The refund was completed and the refunded user received it.',
  markAsRefund: 'Mark as refund',
  authorize: 'Authorize',
  confirmInitRefund:
    'Are you sure you want to initialize refunding for this application?',
  confirmAuthorizeFpx:
    'Are you sure you want to mark this application refunding as FPX Was Authorized?',
  confirmAuthorizeCimb:
    'Are you sure you want to mark this application refunding as CIMB Was Authorized?',
  confirmMarkAsRefund:
    'Are you sure you want to mark the refunding for this application as completed?',
  authorizeFpx: 'Authorize FPX',
  authorizeCimb: 'Authorize CIMB',
  OK: 'OK',
  Cancel: 'Cancel',
  Completed: 'Completed',
  waitingForAuthorizationFpx: 'Waiting For Authorization (FPX)',
  waitingForAuthorizationCimb: 'Waiting For Authorization (CIMB)',
  refundAuthorized: 'Refund Authorized',
};

export default en;
