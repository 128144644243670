import axios from './axios';
import {
  LOGIN,
  VERIFY_EMAIL_CODE,
  LOGIN_BY_TOKEN,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
} from './APILinks';

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<{
 *  email: string,
 *  message: 'LOGIN_SUCCESS'
 * }>}
 */
export const login = (email, password) =>
  axios.post(LOGIN, { email, password });
// export const login = (email, password) =>
//   axios.post(LOGIN, { email: email, password });

/**
 * @param {string} email
 * @param {string} emailVerifyCode
 * @returns {Promise<{
 *  adminInfo: Object,
 *  email: String,
 *  token: String
 * }>}
 */
export const verifyEmailCode = (email, emailVerifyCode) =>
  axios.post(VERIFY_EMAIL_CODE, {
    email,
    emailVerifyCode,
  });

/**
 * Login with token everytime refresh page
 * @param {String} token
 * @returns {Promise<{
 * message: String,
 * adminInfo: Object
 * }}
 */
export const loginByToken = (token) => axios.post(LOGIN_BY_TOKEN);
// export const loginByToken = (token) => axios.post(LOGIN_BY_TOKEN);

/**
 *
 * @param {String} email
 * @returns {Promise<{
 * message: String}}
 */
export const forgetPassword = (email) => axios.post(RESET_PASSWORD, { email });

/**
 *
 * @param {String} token
 * @param {String} newPassword
 * @returns {Promise<{
 * message: String
 * }}
 */
export const resetPassword = (token, newPassword) =>
  axios.put(
    RESET_PASSWORD,
    { newPassword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

/**
 *
 * @param {Object} param0
 * @param {String} param0.newPassword
 * @param {String} param0.currentPassword
 * @returns {Promise<{
 * message: String}}
 */
export const changePassword = ({ newPassword, currentPassword } = {}) =>
  axios.put(CHANGE_PASSWORD, { newPassword, currentPassword });

// TODO: connect with real api
export const logout = (email) => Promise.reject();
// export const logout = () => axios.post(LOGOUT);
