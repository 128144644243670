import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import {
  HomeOutlined,
  FileImageOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  isLoggedInSelector,
  userRoleSelector,
} from 'reduxStore/auth/selectors';
import routeLinks from './routeLinks';
import { accountRole } from 'variables';

const baseRoutes = {
  superAdmin: [
    {
      path: routeLinks.listAccount,
      component: lazy(() => import('src/pages/Accounts')),
      name: 'routeName.accountManager',
      icon: <UserOutlined />,
      exact: true,
    },
    {
      path: routeLinks.createAccount,
      component: lazy(() => import('pages/AccountDetail')),
      exact: true,
      showOnSideBar: false,
    },
    {
      path: routeLinks.editAccount,
      component: lazy(() => import('pages/AccountDetail/Edit')),
      showOnSideBar: false,
    },
  ],
  authorize: [
    {
      path: routeLinks.home,
      component: lazy(() => import('pages/Home')),
      name: 'routeName.home',
      icon: <HomeOutlined />,
      exact: true,
    },
    {
      path: routeLinks.changePassword,
      component: lazy(() => import('pages/ChangePassword')),
      exact: true,
      showOnSideBar: false,
    },
    {
      path: routeLinks.banners,
      component: lazy(() => import('pages/Banners')),
      name: 'routeName.banners',
      icon: <FileImageOutlined />,
      exact: true,
    },
    {
      path: routeLinks.juaraApplicatons,
      component: lazy(() => import('pages/JuaraApplications')),
      name: 'routeName.juaraApplications',
      icon: <FileImageOutlined />,
      exact: true,
    },
    {
      path: routeLinks.tenangApplicatons,
      component: lazy(() => import('pages/TenangApplications')),
      name: 'routeName.tenangApplications',
      icon: <FileImageOutlined />,
      exact: true,
    },
  ],
};

const routesByRole = {
  superAdmin: [...baseRoutes.authorize, ...baseRoutes.superAdmin],
  authorize: baseRoutes.authorize,
  unAuthorize: [
    {
      path: routeLinks.login,
      component: lazy(() => import('pages/Login')),
      showOnSideBar: false,
    },

    {
      path: routeLinks.forgotPassword,
      component: lazy(() => import('pages/ForgotPassword')),
      showOnSideBar: false,
    },
    {
      path: routeLinks.resetPassword,
      component: lazy(() => import('pages/ResetPassword')),
      showOnSideBar: false,
    },
    {
      path: routeLinks.welcome,
      component: lazy(() => import('pages/Welcome')),
      showOnSideBar: false,
    },
  ],
};

const useRouteByRole = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userRole = useSelector(userRoleSelector);

  if (!isLoggedIn) return routesByRole.unAuthorize;

  //get route
  switch (userRole) {
    case accountRole.superAdmin:
      return routesByRole.superAdmin;

    case accountRole.admin:
    default:
      return routesByRole.authorize;
  }
};

export default useRouteByRole;
