import { Spin } from 'antd';
import styled from 'styled-components';

const CenterScreenStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.full-screen {
    width: 100vw;
    height: 100vh;
  }
`;
const PageLoading = ({ fullScreen }) => {
  return (
    <CenterScreenStyle className={fullScreen ? 'full-screen' : ''}>
      <Spin size='large' />
    </CenterScreenStyle>
  );
};

export default PageLoading;
