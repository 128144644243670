export const HOST = process.env.REACT_APP_API_URL;

// TODO: update with the real endpoint
export const ROOT = 'marketing';
export const ROOT_BANNER = `${ROOT}/banners`;

export const LOGIN = `${ROOT}/login`;
export const VERIFY_EMAIL_CODE = `${ROOT}/verify-email-code`;
export const LOGIN_BY_TOKEN = `${ROOT}/login-by-token`;
export const RESET_PASSWORD = `${ROOT}/reset-password`;
export const CHANGE_PASSWORD = `${ROOT}/password`;
export const LOGOUT = `${ROOT}/logout`;

export const CHANGE_BANNER_LIST_ORDER = `${ROOT_BANNER}/change-orders`;
export const ACCOUNT_URL = `${ROOT}/accounts`;
export const JUARA_URL = `${ROOT}/juara`;
export const TENANG_URL = `${ROOT}/perlindungan-tenang`;
